import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ArticleCard({ url, img, title, description }) {
	return (
		<Link to={`/${url}`} className="c-article-card-link">
			<article className="c-article-card">
				<div className="o-row-2">
					<header>
						<div className="c-article-card__img">
							<GatsbyImage image={getImage(img.childImageSharp)} alt="" />
						</div>
						<p className="c-article-card__title">{title}</p>
					</header>
				</div>
				<div className="o-row-2">
					<p className="c-article-card__copy">{description}...</p>
				</div>
				<p className="c-article-card-btn">
					<strong>Keep Reading →</strong>
				</p>
			</article>
		</Link>
	);
}
