import React from 'react';
import { graphql } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/ArticleCard';
import cleanCrumbLabels from '../utils/cleanCrumbLabels';

const Categories = ({ pageContext, data, location }) => {
	const {
		tag,
		breadcrumb: { crumbs }
	} = pageContext;
	const { nodes } = data.allMdx;

	return (
		<Layout>
			<Seo title={`All post related to ${tag}.`} description={`All post related to ${tag}.`} />
			<div className="o-wrapper">
				<div className="o-row-2">
					<h1 className="c-md-h1">All post related to {tag}</h1>
				</div>
				<div className="o-row-3 o-row-6-d">
					<Breadcrumb location={location} crumbSeparator=" / " crumbs={cleanCrumbLabels(crumbs)} />
				</div>
				<div className="o-row-3 o-row-6-d">
					<div className="c-article-card-wrapper">
						{nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={post.slug}
								img={post.frontmatter.featuredImage}
								title={post.frontmatter.headline}
								description={post.excerpt}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Categories;

export const pageQuery = graphql`
	query ($tag: String) {
		allMdx(limit: 1000, filter: { frontmatter: { tags: { in: [$tag] } } }) {
			nodes {
				excerpt(pruneLength: 400)
				frontmatter {
					headline
					description
					featuredImage {
						childImageSharp {
							gatsbyImageData(width: 240, placeholder: BLURRED, formats: AUTO)
						}
					}
				}
				slug
			}
		}
	}
`;
